<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card grid-list-lg>
      <ModelTitle title="New Category" @close="close()" />

      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" sm="12" md="6">
            <ImageView :name="searchName" field='cateogry' @submit='imageChange' />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field focusable
              :error-messages="nameErrors"
              :hide-details="nameErrors.length === 0"
              v-model.trim="Category.name"
              ref="categoryName"
              outlined @change="changeName"
              @focus="$event.target.select()"
              dense
              label="Name"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              counter="counter"
              @focus="$event.target.select()"
              auto-grow
              outlined
              hide-details
              dense
              v-model="Category.note"
              :label="$t('labels.description')"
            >
            </v-textarea>
          </v-col>
          
          <v-col cols="12" md="6">
            <v-switch  color="yellow darken-1"
              v-model="Category.remoteOrder"
              label="Remote Ordering"
              class="mt-2"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch  color="yellow darken-1"
              v-model="Category.catalog"
              label="Catalog Ordering"
              class="mt-2"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch  color="yellow darken-1"
              v-model="Category.applyMarkup"
              label="Apply Markup"
              class="mt-2"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" v-if="Category.applyMarkup">
            <v-textarea
              counter="counter"
              @focus="$event.target.select()"
              auto-grow
              outlined
              hide-details
              dense
              v-model="Category.markupPercentage"
              label="Mark up Percentage"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider> </v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryService from "../service.js";
import GlobalFunction from "@/mixins/GlobalFunction";
import ImageView from '@/components/Image/ImageView'
const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  name: "create-Category",
  props: {
    createDialog: {
      default: false
    },
    createFromStore: {
      type: Boolean
    }
  },
  data() {
    return {
      searchName: null,
      loading: false,
      dialog: false,
      Category: {
        name: null,
        note: null,
        imageUrl: null,
				applyMarkup: false,
				markupPercentage: 0
      }
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    Category: {
      name: {
        required
      }
    }
  },
  watch: {
    createDialog(val) {
      this.dialog = val;
      // this.$nextTick(() => {
      //   this.$refs.categoryName.focus();
      // });
    }
  },
  components: {
    ImageView
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.Category.name.$dirty) return errors;
      if (!this.$v.Category.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  methods: {
    imageChange(val) {
      if (val) {
        this.Category.imageUrl = val;
      }
    },
    changeName() {
      this.Category.name = this.properCase(this.Category.name);
      this.searchName = this.Category.name      
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Category = {
        name: null,
        imageUrl: null,
        note: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
				if (this.Category.applyMarkup && !this.Category.markupPercentage) {
        return this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: "Mark up percentage is required"
          });
				}
        this.loading = true;
        return CategoryService.create(this.Category)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "Category is created"
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
